const DEV_GATEWAY = 'https://gw.dev.edh-nonprod.com';

export const BASE_ENVIRONMENT = {
  production: false,
  logoEnvText: 'LOCAL',
  environmentName: 'Local',
  gateway: DEV_GATEWAY,

  blobStorage: 'https://comedhccarehubdev.blob.core.windows.net',
  claimsFaxLine: '8889654011',
  receiptFax: '8889654011',
  receiptEmail: 'accounting@lanterncare.com',
  preLaunchTheme: 'Dark,Light',
  launchStatus: 'PreLaunch,Launched',
  // deepcode ignore HardcodedNonCryptoSecret: just a key, not a secret
  googleMapsApiKey: 'AIzaSyAsoRR9YHAbG75tvj0xT1uWGjJA9TMSsjc',
  surveyJsKey:
    'M2U3N2RhZGEtODI2NC00YTdjLTgwNzktY2ZhY2E3MTY0NDZhOzE9MjAyNS0xMS0yMSwyPTIwMjUtMTEtMjEsND0yMDI1LTExLTIx',
};

export const OIDC_DEFAULTS = {
  redirectUri: origin,
  silent_redirect_uri: '/',

  clientId: '600b8dbe-9a0e-4ad7-b182-9b065b60a1f5',
  authority:
    'https://login.microsoftonline.com/0d6c0dcf-4852-485d-be69-f9b3df73a715',
  scopes: 'api://600b8dbe-9a0e-4ad7-b182-9b065b60a1f5/carehub',
};

export const APP_INSIGHTS_DEFAULTS = {
  enableCorsCorrelation: true,
  autoTrackPageVisitTime: true,
  verboseLogging: true,
  disableDataLossAnalysis: false,
  disableCorrelationHeaders: false,
  correlationHeaderExcludedDomains: Array<string>(),
};
