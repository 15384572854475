import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { configureEnvironment } from './configureSettings';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

configureEnvironment().then(() => {
  // we have to lazy load the top level module to ensure that the environment
  // is fully loaded.
  import('./app/app.module').then((m) =>
    platformBrowserDynamic()
      .bootstrapModule(m.AppModule)
      .catch((err) => console.log(err))
  );
});
